#cardContainer, #cardContainer form {
  width: 100%;
}

#cardContainer form {
  margin-top: 1rem;
  padding: 30px 20px;
  border-radius: 8px;
  background-color: #CCC;
}

#cardContainer #brand {
  text-transform: uppercase;
  position: absolute;
  right: 100px;
  margin-top: 12px;
}

#cardContainer iframe {
  background: #fff;
  border: #ececf5 2px solid;
  border-radius: 25px;
  color: #B0B0B0;
  font-weight: bold;
  height: 50px;
  line-height: 46px;
  outline: 0;
  padding: 0 1em;
  transition: border 0.5s;
  width: 300px;
}

#cardContainer button {
  background-color: #64b1ff;
  color: #fff;
  padding: 12px;
  border-color: #64b1ff;
  border-radius: 25px !important;
  height: 50px;
  font-weight: 600;
  cursor: pointer !important;
  margin-top: 1rem !important;
}