// COLOR SCHEME

$pari-blue: #64b1ff;
$pari-alt-blue: #3E82FE;
$pari-secondary-blue: #2B7BFF;
$pari-white: #fff;
$pari-text-gray: #B0B0B0;
$pari-alt-gray: #7B7B7B;
$pari-secondary-gray: #343a20;
$pari-dark-gray: #323334;
$hr-gray: #EEE;
$border-gray: #D2D2D2;
$close-border-gray: #DFDFDF;
$border-blue: #2B7BFF;
$pari-black: #000;
$pari-orange: #f79c41;
$pari-red: #D0021B;
$pari-green: #6e8309;