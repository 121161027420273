@import './Mixins.scss';

html, body {
  height: 100%;
}

body {
  background: $pari-white;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root, .App {
  min-height: 100vh;
  overflow-y: auto;

  .app-body {
    min-height: 100vh;
    padding-bottom: 82px;

    &--page {
      min-height: calc(100vh - 76px);
    }
  }

  .app-blue {
    background: $pari-blue;
  }
}

.form-group {
  text-align: left;
  .form-label {
    padding: 0 18px;
  }
}

.App input, input.form-control, select.form-control, textarea.form-control {
  background: transparent;
  border: #ececf5 2px solid;
  border-radius: 25px;
  color: $pari-text-gray;
  font-weight: 500;
  height: 50px;
  line-height: 46px;
  outline: 0;
  padding: 0 1em;
  transition: border 0.5s;
  
  &::placeholder {
    color: $pari-text-gray;
  }
  &:active, &:focus, &:hover {
    background: transparent;
    border-color: $pari-text-gray !important;
    box-shadow: none;
    color: $pari-text-gray;
    outline-color: transparent;
  }

  &.white {
    border-color: $pari-white;
    color: $pari-white;
    &::placeholder {
      color: $pari-white;
    }
    &:active, &:focus, &:hover {
      border-color: $border-gray !important;
      color: $pari-white;
    }
  }
}

.App textarea.form-control {
  height: auto;
  line-height: 2em;
  padding: 1em;
}

.App select.form-control {
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 12px);
  background-position-y: 12px;
  padding-right: 3em;
}

input.form-control.search-box {
  background-color: $hr-gray !important;
  outline: none;
  border: none;
  background-image: url('./assets/search.png');
  background-position: 10px;
  background-repeat: no-repeat;
  padding-left: 40px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.input-icon {
  position: relative;

  label {
    top: 10px;
    left: 20px;
    position: absolute;

    img {
      width: 22px;
      height: 22px;
    }
  }

  input.form-control {
    padding-left: 50px;
  }
}

.close-btn {
  position: absolute;
  top: 0.15rem;
  left: 0.65rem;
}

hr {
  border-top: 1px solid $hr-gray;
}

.filled {
  background-color: $pari-blue;
}

.vh-100 {
  height: 100vh;
}

.filled-white {
  background-color: $pari-white;
  box-shadow: rgba(0,0,0,0.5) 0 2px 4px 0;
}

.menu-button-left {
  position: absolute;
  left: 0.5rem;
  top: 0;
}

.menu-button-right {
  position: absolute;
  right: 0.5rem;
  top: 0;
}

header {
  display: block;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  top: 0;
  height: 44px;
  z-index: 1;
}

.btn, .btn-lg {
  border-radius: 6px !important;
  white-space: nowrap;
}

.btn:disabled {
  opacity: 0.4;
}

.btn-primary {
  background-color: $pari-blue;
  border-color: $pari-blue;
  border-radius: 25px !important;
  height: 50px;
  font-weight: 600;

  &.btn-sm {
    height: 36px;
  }
}

.btn-primary:hover,.btn-primary:active,.btn-primary:focus {
  background-color: $pari-alt-blue !important;
  border-color: $pari-alt-blue !important;
  outline: 0;
  box-shadow: none !important;
}

.btn-secondary {
  background-color: $pari-alt-blue;
  border-color: $pari-alt-blue;
  border-radius: 25px !important;
  height: 50px;
  line-height: 36px;
  font-weight: 500;

  &.btn-sm {
    height: 36px;
  }
}

.btn-secondary:hover,.btn-secondary:active,.btn-secondary:focus {
  background-color: $pari-secondary-blue !important;
  border-color: $pari-secondary-blue !important;
  outline: 0;
  box-shadow: none !important;
}

.btn-danger {
  border-radius: 25px !important;
  height: 50px;
  font-weight: 600;
  outline: 0;
}

.btn-danger:hover,.btn-danger:active,.btn-danger:focus {
  outline: 0;
  box-shadow: none !important;
}

.btn-success {
  border-radius: 25px !important;
  height: 50px;
  font-weight: 600;
  outline: 0;
}

.btn-success:hover,.btn-success:active,.btn-success:focus {
  outline: 0;
  box-shadow: none !important;
}

.btn-light {
  background-color: $pari-white;
  border-color: $pari-white;
  color: $pari-blue;
  border-radius: 25px !important;
  height: 50px;
  line-height: 36px;
  font-weight: 500;
}

.btn-light:hover,.btn-light:active,.btn-light:focus {
  background-color: $border-gray !important;
  border-color: $border-gray !important;
  color: $pari-blue !important;
  outline: 0;
  box-shadow: none !important;
}

.btn-outline-primary {
  background-color: $pari-white;
  border-color: $pari-blue;
  border-radius: 25px !important;
  border-width: 2px;
  color: $pari-blue;
  height: 50px;
  font-weight: 600;

  &.btn-sm {
    height: 36px;
  }
}

.btn-outline-primary:hover,.btn-outline-primary:active,.btn-outline-primary:focus {
  background-color: $pari-white !important;
  border-color: $pari-alt-blue !important;
  color: $pari-alt-blue !important;
  outline: 0;
  box-shadow: none !important;
}

.btn-link {
  color: #343a40 !important;
  outline: none;
  border: none;
}

.title {
  font-size: 2.5rem;
  font-weight: 300;
  margin: 0;

  &.blue {
    color: $pari-blue;
  }
}

.subtitle {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;

  &.blue {
    color: $pari-blue;
  }
}

.nav-link {
  color: $pari-blue;
  display: inline;
  padding: 0;

  &:hover, &:active {
    color: $pari-blue;
    text-decoration: underline;
  }

  &--alt {
    color: $pari-alt-blue;

    &:hover, &:active {
      color: $pari-alt-blue;
    }
  }
}

.badge {
  border-radius: 10px !important;
  height: 20px;
  line-height: 20px;
  padding: 0 10px;

  &.badge-primary {
    background-color: $pari-blue;
  }
}

.user-avatar-sm img {
  height: 28px;
  border-radius: 14px;
}

.user-avatar-md img {
  height: 40px;
  border-radius: 20px;
}

.user-avatar-lg img {
  height: 64px;
  border-radius: 32px;
}

.navigate-back {
  position: absolute;
  left: -0.75rem;
  top: -0.5rem;
}

.footer {
  background: $hr-gray;
  border-top: $border-gray 1px solid;
  bottom: 0;
  height: 60px;
  position: fixed;
  width: 100%;
  z-index: 2;

  &__container {
    display: flex;
    justify-content: space-between;
  }

  &__link {
    height: 60px;
    line-height: 60px;
    padding: 0;
    width: 54px;

    svg {
      fill: #9f9f9f;

      &.selected {
        fill: $pari-blue;
      }
    }

    &:hover {
      svg {
        fill: $pari-blue;
      }
    }
  }

  &__button {
    margin-top: -30px;
    padding: 0;
    width: 80px;
  }
}

[aria-label="Close"]:hover {
  fill: transparent;
}

.copyright {
  text-align: center;
  position: fixed;
  bottom: 0;
  margin-top: 15px;
}

.history, .financials {
  overflow: overlay;
  padding-bottom: 50px;
}

.swal2-container {
  .swal2-confirm {
    background-color: $pari-alt-blue;
  }
  .swal2-confirm, .swal2-cancel {
    border-width: 0 !important;
    margin: 0 16px;
    color: #fff;
    height: 50px;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 25px;
    text-transform: uppercase;
    min-width: 100px;
  }
}


// MainView

.balance-jumbo {
  color: $pari-white;
  background-color: $pari-blue;
  box-shadow: rgba(0, 0, 0, 0.2) 0 1px 15px 0;
  border-radius: 6px;
  height: 125px;
}

.income-jumbo {
  color: $pari-white;
  background-color: $pari-blue;
  box-shadow: rgba(0, 0, 0, 0.2) 0 1px 15px 0;
  border-radius: 6px;
  height: 90px;
}

.expense-jumbo {
  color: $pari-white;
  background-color: $pari-red;
  box-shadow: rgba(0, 0, 0, 0.2) 0 1px 15px 0;
  border-radius: 6px;
  height: 90px;
}

.financial-upper {
  top: 44px;
  padding-top: 1rem;  
  background: $pari-white;
}

.financial-body {
  margin-top: calc(200px + 1rem);
}

.filtered-button {
  filter: invert(44%) sepia(39%) saturate(6737%) hue-rotate(207deg) brightness(101%) contrast(104%);
}

.notifications-menu {
  box-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px;
  margin-left: -15px;
  margin-right: -15px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 44px;
  .btn-link {
    padding-top: 0.25rem;
    color: $pari-text-gray !important;
    font-size: 90%;
    height: 100%;
    &:hover, &:active {
      text-decoration: none;
    }
  }
  .active-notification-btn {
    color: $pari-blue !important;
  }
}

.swal2-container {
  z-index: 9999 !important;
}